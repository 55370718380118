<template>
  <div
    class="cotainer"
    v-loading="loading">
    <el-breadcrumb
      class="p_tb_10"
      separator-class="el-icon-arrow-right">
      <el-breadcrumb-item @click.native="prev">返回上一级</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/party/schoolParty' }">
        学校党建
      </el-breadcrumb-item>
      <el-breadcrumb-item>详情页</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex_row flex_justify_between">
      <div class="box-left m_b_20">
        <div class="box">
          <Headline title="党组织概况"></Headline>
          <div
            class="introduce"
            v-if="list.dzzgk">
            {{ list.dzzgk.content }}
          </div>
          <el-empty v-else></el-empty>
        </div>
        <div class="box">
          <Headline title="党建品牌介绍"></Headline>
          <div
            class="introduce"
            v-if="list.ppjs">
            <img
              :src="list.ppjs.coverUrl"
              style="width: 100%; height: 260px; object-fit: cover" />
            {{ list.ppjs.content }}
          </div>
          <el-empty v-else></el-empty>
        </div>
        <div class="box">
          <Headline title="党员风采"></Headline>
          <Carousel
            v-if="list.dyfc"
            :datas="list.dyfc"
            :pageId="$route.query.cid"></Carousel>
          <el-empty v-else></el-empty>
        </div>
        <div class="box">
          <Headline title="志愿服务"></Headline>
          <NewList
            v-if="list.zyfw"
            :datas="list.zyfw"
            :type="5"
            :pageId="$route.query.cid"></NewList>
          <el-empty v-else></el-empty>
        </div>
        <div class="box">
          <Headline title="走访慰问"></Headline>
          <NewList
            v-if="list.zfww"
            :datas="list.zfww"
            :type="5"
            :pageId="$route.query.cid"></NewList>
          <el-empty v-else></el-empty>
        </div>
      </div>
      <div class="box-right">
        <div class="box">
          <Headline title="党建动态"></Headline>
          <NewList
            v-if="list.djdt"
            :datas="list.djdt"
            :type="2"
            :pageId="$route.query.cid"></NewList>
          <el-empty v-else></el-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeptDetail } from '@/api/party/index';
import Headline from '../components/Headline';
import Carousel from '../components/Carousel';
import NewList from '../components/NewList';
export default {
  name: 'index',
  data: () => {
    return {
      loading: false,
      list: {
        dzzgk: [],
        ppjs: [],
        dyfc: [],
        zyfw: [],
        zfww: [],
        djdt: [],
      },
    };
  },
  components: {
    Headline,
    Carousel,
    NewList,
  },
  async created() {
    //this.$route.query.cid
    //获取内容详情 1党组织概况 2党建品牌简介 3党建动态 4党员风采 5志愿服务 6走访慰问
    this.loading = true;
    const {
      data: { records: dzzgk },
    } = await getDeptDetail({
      deptId: this.$route.query.cid,
      isExamine: 1,
      types: 1,
    });
    const {
      data: { records: ppjs },
    } = await getDeptDetail({
      deptId: this.$route.query.cid,
      isExamine: 1,
      types: 2,
    });
    const {
      data: { records: djdt },
    } = await getDeptDetail({
      deptId: this.$route.query.cid,
      isExamine: 1,
      types: 3,
      size: 9,
      current: 1,
    });
    const {
      data: { records: dyfc },
    } = await getDeptDetail({
      deptId: this.$route.query.cid,
      isExamine: 1,
      types: 4,
      size: 1000,
      current: 1,
    });
    const {
      data: { records: zyfw },
    } = await getDeptDetail({
      deptId: this.$route.query.cid,
      isExamine: 1,
      types: 5,
      size: 1000,
      current: 1,
    });
    const {
      data: { records: zfww },
    } = await getDeptDetail({
      deptId: this.$route.query.cid,
      isExamine: 1,
      types: 6,
      size: 1000,
      current: 1,
    });
    this.list.dzzgk = dzzgk[0];
    this.list.ppjs = ppjs[0];
    this.list.djdt = djdt;
    this.list.dyfc = dyfc;
    this.list.zyfw = zyfw;
    this.list.zfww = zfww;
    this.loading = false;
  },
  methods: {
    prev(){
      this.$router.back();
      sessionStorage.setItem('detail',true);
    }
  },
};
</script>

<style
  lang="scss"
  scoped>
.cotainer {
  .box {
    margin: 10px 0;
  }

  .box-left {
    width: 57%;

    .introduce {
      background-color: #fff;
      margin-top: 15px;
      padding: 10px;
      font-size: 16px;
    }
  }

  .box-right {
    width: 40%;
  }
}
</style>
