<template>
  <div class="carousel">
    <div class="card" v-for="(item) in datas" :key="item.id" @click="$router.push('/party/exemplaryVanguard/partyDetail?from=1'+'&cid='+item.id+'&pageId='+pageId)">
      <img :src="item.coverUrl" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Carousel',
    data: () => {
      return {

      }
    },
    props: {
      datas: [],
      pageId:{
        type:String,
        default:''
      }
    },
    methods: {

    }
  }
</script>

<style scoped lang="scss">
  .carousel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    // width: 600px;



    .card {
      text-align: center;
      width: 20%;
      padding-top: 15px;
      flex-shrink: 0;

    }

    img {cursor: pointer;
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 100%;
    }
  }
</style>
